@use '@angular/material' as mat;

/* You can add global styles to this file, and also import other style files */


:root {

  --main-color: #88B140;
  --alternate-color: #613825;

}

.blank-container {

  background-image: url(/assets/images/background/login-register.jpg);

}


.navbar-brand {

  img {

    height: 40px;

  }

}

// http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5

@import '@angular/material/theming';

$primary-palette: (
  50 : #f1f6e8,
  100 : #dbe8c6,
  200 : #c4d8a0,
  300 : #acc879,
  400 : #9abd5d,
  500 : #88b140,
  600 : #80aa3a,
  700 : #75a132,
  800 : #6b982a,
  900 : #58881c,
  A100 : #e2ffc2,
  A200 : #caff8f,
  A400 : #b2ff5c,
  A700 : #a6ff42,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$secondary-palette: (
  50 : #ece7e5,
  100 : #d0c3be,
  200 : #b09c92,
  300 : #907466,
  400 : #795646,
  500 : #613825,
  600 : #593221,
  700 : #4f2b1b,
  800 : #452416,
  900 : #33170d,
  A100 : #ff906f,
  A200 : #ff683c,
  A400 : #ff4109,
  A700 : #ef3600,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$primary: mat.define-palette($primary-palette, 600);
$accent: mat.define-palette($secondary-palette, 500);
$warn: mat.define-palette(mat.$red-palette, 500);
$theme: mat.define-light-theme($primary, $accent, $warn);

@include mat.all-legacy-component-themes($theme);
